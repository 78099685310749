import React from 'react'
import Layout from '../components/layout'
import Head from '../components/head';
import layoutStyles from '../styles/style.module.scss'
import { Link } from 'gatsby'

const ProjectsPage = () => {
    return (
        <div>
            <Head title= "projects"/>
            <Layout>
                <h1 className={layoutStyles.t1}>Contributions</h1>
                <p className={layoutStyles.parag}>100+ contributions since 2007 (consultancy) / 2004 (research). <Link className={layoutStyles.link} to="/contributions"> List.</Link></p>
                <h1 className={layoutStyles.t1}>Personal</h1>
                <p className={layoutStyles.parag}><a href="https://guaecast.hpgregorio.net" target="_blank" rel="noopener noreferrer" className={layoutStyles.link}>Guaecast: operational wave forecast system for Baixada Santista, São Sebastião and Ubatuba, SP, Brazil.</a></p>
                <p className={layoutStyles.parag}><a href="https://when2board.hpgregorio.net" target="_blank" rel="noopener noreferrer" className={layoutStyles.link}>When2board: Decide when it's time to (take your) board!</a></p> 
            </Layout>
        </div>

    )
}

export default ProjectsPage
